import styled from 'styled-components';

export const PageHeading = styled.p`
    font-size: 19px;
    color: #A73752;
		text-align: center;
`;

export const PageInfo = styled.p`
    color: #908B9C;
    font-size: 14px;
    line-height: 1.4em;
		text-align: center;
`;

export const HomeLink = styled.a`text-decoration:none !important;`;

export const VLink = styled.div`padding: 12px; padding-left: 3px; text-align:center; margin-top:30px;`;
