import React, { useEffect } from 'react';

import { HomeLink, PageHeading, PageInfo, VLink } from './pageNotFoundCss';
import { ASSET_PATH } from 'assetsPath';
import { getXrequestId, saveServerLogs } from './Util';
import MetaTags from 'common/metaTags';
import { APPLICATION_CONSTANTS, PATHS } from 'AppConstants';

const notFoundImg = ASSET_PATH.IMAGES + 'server-error.png';

const PageNotFound = ({ staticContext }) => {
	if (staticContext) {
		staticContext.statusCode = APPLICATION_CONSTANTS.NOT_FOUND_ERROR_CODE;
	}

	useEffect(() => {
		saveServerLogs('page not found', 'warn');
	}, []);

	return (
		<div style={{ 'margin-top': '100px' }}>
			<div>
				<p style={{ 'text-align': 'center' }}>
					<img width='250' src={notFoundImg} alt='Error' />
				</p>
			</div>
			<MetaTags titleTxt='Page not found - myBiz by MakeMyTrip' />
			<VLink>
				<PageHeading>Oops, page that you are looking for could not be found!!!</PageHeading>
				<br />
				<PageInfo>Seems like this page might have moved, is temporarily unavailable or an
					incorrect URL has been entered. (code :{getXrequestId()})
				</PageInfo>
				<br />
				<HomeLink href={PATHS.MYBIZ_URL}>Visit Home Page</HomeLink>
			</VLink>
		</div>
	);
};

export default PageNotFound;
